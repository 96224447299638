<doc>
    Network Vue.js component
</doc>
<template>
    <div>
        <Tabs>
            <Tab name="Connections">
                <MyNetworkConnections />
            </Tab>
            <Tab name="Manual">
                <MyNetworkManualListing />
            </Tab>
			<Tab name="Following">
                <MyNetworkFollowing />
            </Tab>
            <Tab name="Archive">
                <MyNetworkArchive />
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import _isNull from "lodash/isNull"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("my-test")

import {
  Tab,
  Tabs,
  MyNetworkConnections,
  MyNetworkManualListing,
  MyNetworkFollowing,
  MyNetworkArchive
} from "../components"
export default {
  name: "MyNetworkNew",
  components: {
    Tabs,
    Tab,
    MyNetworkConnections,
    MyNetworkManualListing,
	MyNetworkFollowing,
    MyNetworkArchive
  },
  async mounted(){
    await this.getNetworks()
  },
  data() {
    return {
        networks: null,
    }
  },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            async getNetworks() {
            },
        }
    ),
}
</script>