<doc>
  Theme selector Vue.js component
</doc>

<template>
  <div class="fts" v-show="!isLoading">
    <div class="tags-title"><h2>Tag Filter Options</h2></div>
    <div class="selected-tags">
      <span v-for="tag in selectedTags" :key="tag.id">{{tag.name}}<a href="javascript:void" class="remove-tag" @click="removeTag(tag.id)">x</a></span>
    </div>
    <div class="mf-wrapper">
      <div class="tags-selctor">
        <ul>
          <li v-for="tag in tags" :key="tag.id">
            <label :for="`tag-${tag.id}`">
              <input :id="`tag-${tag.id}`" type="checkbox" class="chk-tag" :data-id="tag.id" :data-name="tag.name" @change="selectTag($event)" />
            {{ tag.name }}({{ tag.tcount }})
          </label>
          </li>
        </ul>
      </div>
      <grid-row>
        <grid-column v-for="template in templates" :key="template.id" :class="`item-template ${template.show}`">
		<div class="template-names">{{(template.alias==null)?template.name:template.alias}}</div>
          <div
            :class="wrapperClassName(template.id)"
            v-on:click="setTemplate(template.id)"
          >	
			<div v-if="template.preview_url" style="height: 440px; display: flex; justify-content: center; align-items: center;">
				<img class="fts__thumb" :src="template.preview_url" />
			</div>
			<div v-else>
				<div style="width: 337px; height: 440px;"></div>
			</div>
            <icon
              class="fts__check-icon"
              name="check"
              v-if="template.id === themeSelected"
            />
          </div>
          <div class="template-tags"><span v-for="t in template.tags" :key="t.id">{{ t.name }}</span></div>
        </grid-column>
      </grid-row>
  </div>
  <div v-if="swatches" class="fts__color-selector">
        <div
          v-for="(swatch, index) in swatches"
          :key="index"
          :class="swatchClassName(index)"
          v-on:click="setSwatch(index)"
        >
          <span :style="`background-color: ${swatch.primaryColor}`"></span>
          <span
            :style="
              `background-color: ${
                swatch.secondaryColor
                  ? swatch.secondaryColor
                  : swatch.primaryColor
              }`
            "
          ></span>
          <icon
            class="fts__check-icon is-small"
            name="check"
            v-if="index === swatchSelected"
          />
        </div>
      </div>
      <LoaderPlaceholder v-if="isLoading" type="circle" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import _find from "lodash/find"
// TODO: this should be in a global scope, not with namespace
const { mapState, mapActions } = createNamespacedHelpers("manualListing")
export default {
  name: "ThemeSelector",
  props: {
    onThemeSelect: { type: Function, required: true },
    theme: { type: [Number, Boolean, String], default: false },
    swatch: { type: [Number, Boolean], default: false }
  },
  data: function() {
    return {
      swatchesold: null,
      tags: [],
      selectedTags:[]
    }
  },
  computed: Object.assign({}, mapState(["templates"]), {
    themeSelected: function() {
      return this.theme
    },
    swatchSelected: function() {
      return this.swatch
    },
    swatches: function() {
      let swatches = null
      if (this.theme && this.templates) {
        const template = _find(this.templates, ["id", this.theme])
        if (template && template.settings && template.settings.swatches) {
          swatches = template.settings.swatches
        }
      }
      return swatches
    },
    isLoading: function() {
      return !this.templates
    }
  }),
  methods: Object.assign({}, mapActions(["getTemplates"]), {
    setTemplate: function(template_id) {
      this.onThemeSelect(template_id, "theme")
      this.onThemeSelect(null, "swatch")
    },
    setSwatch: function(index) {
      this.onThemeSelect(index, "swatch")
    },
    wrapperClassName: function(template_id) {
      return `fts__option${
        template_id === this.themeSelected ? " is-selected" : ""
      }`
    },
    swatchClassName: function(index) {
      return `fts__option is-small is-swatch${
        index === this.swatchSelected ? " is-selected" : ""
      }`
    },
    getTags: function(){
      let that = this
      $.ajax({
                type: 'GET',
                url: '/api/v1/template-tag',
                contentType: "application/json",
                dataType: "json",
                data: {
                    status: 1,
                    withcount:1
                },
                success: function (response) {
                    // Display errors.
                    if (response.status != 'error') {
                        that.tags = response
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });
    },
    selectTag: function(e){
      let tag_id = e.target.dataset.id;
      let tag_name = e.target.dataset.name;
      let temp = this.selectedTags;
      let my_templates = this.templates
      console.log(my_templates);
      if(e.target.checked){
        temp.push({id:tag_id, name:tag_name})
        this.selectedTags = temp;
      }
      else{
        temp = temp.filter((item)=>item.id !== tag_id)
        this.selectedTags = temp;

        
      }

      let count = $('.chk-tag:checked').length;
      if(count == 0){
        for(let i=0;i<my_templates.length;i++){
          my_templates[i].show = '';
        }
      }
      else{
        for(let i=0;i<my_templates.length;i++){
            if(my_templates[i].tags != null) {
              let temp_tags = my_templates[i].tags;
              
              temp_tags = temp_tags.map(item=>item.id);
              let temp_ids = temp.map(item=>item.id);
              const template_has_tag = temp_tags.filter(value => temp_ids.includes(value));
              //if(template_has_tag.length == 0){
              if(temp_ids.sort().toString() !== template_has_tag.sort().toString()){
                  my_templates[i].show = 'd-none';
              }
              else{
                my_templates[i].show = '';
              }
            }
            else{
              my_templates[i].show = 'd-none';
            }
        }
      }
      
      this.templates = my_templates
    },
    removeTag: function(tag_id){

      $('.tags-selctor #tag-'+tag_id).prop('checked', false)
      let temp = this.selectedTags;
      temp = temp.filter((item)=>item.id !== tag_id)
      this.selectedTags = temp;
      let my_templates = this.templates;
	  let count = $('.chk-tag:checked').length;
      if(count == 0){
        for(let i=0;i<my_templates.length;i++){
          my_templates[i].show = '';
        }
      }
      else{
		for(let i=0;i<my_templates.length;i++){
		  if(my_templates[i].tags != null) {
			let temp_tags = my_templates[i].tags;
			
			temp_tags = temp_tags.map(item=>item.id);
			let temp_ids = temp.map(item=>item.id);
			const template_has_tag = temp_tags.filter(value => temp_ids.includes(value));
			//if(template_has_tag.length == 0){
      if(temp_ids.sort().toString() !== template_has_tag.sort().toString()){
				my_templates[i].show = 'd-none';
			}
			else{
			  my_templates[i].show = '';
			}      
		  }
		}
	  }
        this.templates = my_templates
    }
  }),
  created() {
    this.getTags();
    this.getTemplates()

    
  }
}
</script>

<style lang="scss" src="./ThemeSelector.scss" scoped></style>
<style scoped>
#flyerTemplateSelect .modal-dialog .modal-body .row div.col {
	padding-left: 0px;
	min-width: 352px;
	flex-basis: 352px;
}
#flyerTemplateSelect .modal-dialog .modal-body .row .col .fts__option {
	margin-right: 15px;
}
.col.item-template  .template-tags {
	display: flex;
    max-width: 337px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.template-names {
	font-size: 12px;
	font-weight: bold;
	background: #34aabc;
	margin-right: 15px;
	padding: 10px;
	margin-bottom: 1px;
	border-radius: 4px 4px 0px 0px;
	color: #fff;
}
.template-tags{
	margin-top: 10px;
}
.template-tags span, .selected-tags span{
	padding: 4px 8px;
	background: #156082;
	margin-right: 5px;
	color: #fff;
	border-radius: 5px;
	margin-bottom: 5px;
}
.show{
	display: block;
}
.mf-wrapper{
	display:flex;
}
.tags-selctor{
	width: 325px;
	max-height: 437px;
    overflow: hidden;
    overflow-y: auto;
    margin-right: 20px;	
}
.mf-wrapper .row{
	width: calc(100% - 325px);
}
.tags-title h2{
	margin-bottom: 15px;
}
.tags-selctor ul li{
	margin: 5px 0;
}
.remove-tag{
	margin-left: 5px;
	color:white;
}
.selected-tags {
	margin: 8px 0;
    position: absolute;
    top: -74px;
    left: 300px;
    display: flex;
    width: 74%;
    text-wrap: auto;
    flex-wrap: wrap;
    height: 65px;
    align-items: center;	
}
.selected-tags span {
    padding: 4px 8px;
    background: #156082;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    height: 25px;
}	
.selected-tags span a {
	margin-left: 20px;
}
.mf-wrapper .row {
	margin-top: -35px;
}
.fts__option div img {
	max-height: 440px;
}
</style>