<doc>
    Connections Vue.js component
</doc>
<template>
    <div>
        <header class="network-header row">
            <div class="network-header__description">
                <p><span style="font-weight: bolder;">Find a realtor</span></p>
                <p>Search by realtor MLS# (recommended) or Last Name. </p>
                <p>More filters can be applied to the results.</p>
                <!--<p><span style="font-weight: bolder;">Note:</span> We have listings for most areas in the US. Here is a list of the MLSs we support: <a target="_blank" href="https://www.listhub.com/broker-mls-list.html">MLS List</a></p>-->
            </div>

            <form class="network-header__search network-search" method="get" action="/my-bb-network/search">
                <div class="network-search__input">
                    <input type="hidden" name="type" value="all">
                <input type="search" class="network-typeahead" placeholder="Search by Realtor MLS ID#" name="name" id="tt-search-input">
                </div>

                <button id="tt-search-btn" type="submit" class="network-search__button btn btn-modern">
                <span class="glyphicon glyphicon-search"></span>
                </button>                
                <div class="network-search__loader loader loader-circle hide tt-loading"></div>
                <div class="break"></div>
                <label style="padding-left: 1em;">
                    <input type="checkbox" value="1" name="search_mls" class="search-mls" /> Search by Realtor Last Name
                </label>
            </form>
        </header>
		<LoaderPlaceholder v-if="isLoading" type="circle" />
        <template v-for="following of followings">           
                
                <div :id="`network-invite-${following.id}`" :class="['network-table__row', 'network-row']" :data-realtor-id="following.id" :data-agency-name="following.agency_name" :data-state="following.state" v-bind:key="following.id">
                    <div class="network-table__column network-table__picture">
                        <img :src="`${following.picture}`" :alt="following.name">
                    </div>
                    <div class="network-table__column network-table__user-info">
                        <span class="network-table__user-info-title">{{following.name}}</span>
                        <span class="network-table__user-info-subtitle visible-xs-block">{{following.agency_name}}</span>
                    </div>
                    <div class="network-table__column hidden-xs network-table__state">{{following.state}}</div>
                    <div class="network-table__column hidden-xs network-table__agency">{{following.agency_name}}</div>
                    <div class="network-table__column hidden-xs network-table__mls">
                        <span class="tooltip-hover" v-for="mls_board of following.mls_board" v-bind:key="mls_board.key">
                            <span class="mls-key">{{ mls_board.key }}</span> 
                        <label class="btn-tooltip-label" style="display: none;">{{mls_board.value}}</label>
                        </span>
                    </div>
                    <div class="network-table__column network-table__actions">
                        <span class="btn-invite-span">
                            <button v-if="!following.invited" data-iid="-1" :data-loid="following.loid" :data-rid="following.id" href="#" class="btn btn-modern network-table__action" @click="send_invite(following.id, following.loid, following.status)">+ Invite Realtor</button>
                            <span v-else-if="following.paired" class="network-table__action network-table__action--invitation-tag">Already paired</span>
                            <span v-else class="network-table__action network-table__action--invitation-tag">Invitation sent</span>
                        </span>
                        <button :data-loid="following.loid" :data-rid="following.id" href="#" class="btn btn-modern network-table__action following">Following</button>
                        <button href="#" @click="showLogs(following.id)" data-action="edit" class="network-table__action network-table__action--icon btn-has-tooltip" style="cursor: pointer;">
                            <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                            <label class="tooltip-edit btn-tooltip-edit" :data-iid="following.id" >Edit</label>
                        </button>
						<button style="cursor:pointer;" :data-pid="following.id" data-action="unfollow-realtor" @click="unfollow(following.loid, following.id)" class="network-table__action network-table__action--icon btn-unfollow">
                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            
        </template>
        <div class="modal jt-modal fade realtor-unfollow-modal" id="realtor-unfollow-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="myModalLabel">Unfollow Confirmation</h4>
                    </div>
                    <div class="modal-body">
                    <p>Please confirm you want to <strong>STOP</strong> following:</p>
                    <p class="follow-realtor-name"></p>
                    <p class="follow-realtor-MLSID"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-default stop-following" @click="unfollow_now">Stop Following</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal jt-modal fade propmix-alerts-modal" id="propmix-alerts-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="myModalLabel">Propmix Alerts</h4>
                    </div>
                    <div class="modal-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Delivery ID</th>
                                <th>Alert ID</th>
                                <th>Alert Name</th>
                                <th>PMX Agent ID</th>
                                <th>LO ID</th>
                                <th>Address</th>
                                <th>Property Type</th>
                                <th>Listing ID</th>
                                <th>MLS Board</th>
                                <th>Listing Contract Date</th>
                                <th>Current Listing Status</th>
                                <th>Current Listing Price</th>
                                <th>Previous Lising Price</th>
                                <th>Previous Listing Status</th>
                                <th>Close Date</th>
                                <th>Close Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="alert of alerts" v-bind:key="alert.id">
                                <td>{{ alert.DeliveryId }}</td>
                                <td>{{ alert.AlertId }}</td>
                                <td>{{ alert.AlertName }}</td>
                                <td>{{ alert.PMXAgentID }}</td>
                                <td>{{ alert.CustomerUniqueId }}</td>
                                <td>{{ alert.Address }}</td>
                                <td>{{ alert.PropertyType }}</td>
                                <td>{{ alert.ListingId }}</td>
                                <td>{{ alert.MLSBoard }}</td>
                                <td>{{ alert.ListingContractDate }}</td>
                                <td>{{ alert.CurrentListingStatus }}</td>
                                <td>{{ alert.CurrentListingPrice }}</td>
                                <td>{{ alert.PreviousListingPrice }}</td>
                                <td>{{ alert.PreviousListingStatus }}</td>
                                <td>{{ alert.CloseDate }}</td>
                                <td>{{ alert.ClosePrice }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <!--- Modal Popup - External Invite Detail --->
        <div class="modal fade jt-modal" id="invite-info-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Invite Details</h4>
                        <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
                    </div>
                    <div class="modal-body">
                        <div class="modal_loading hide loader loader-circle tt-loading"></div>
                        <div class="modal_no-results hide">
                            There are no details to show.
                        </div>
                        <div class="content" style="overflow-x:scroll;">

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <!--- Modal Popup - External Invite Detail --->

        <!--- Modal Popup Accept Invite --->
        <div id="confirm_msa_modal1" class="modal jt-modal fade" role="dialog">
            <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirm Invite</h4>
                    <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
                </div>

                <div class="modal-body">
                    <div class="loader vertical-align-middle loader-circle" style="display:none;" data-type="default"></div>
                    <div class='myInviteConfirmContent'>
                        <p>is a marketing network that is intended to allow Loan Officers with <strong>existing</strong> relationships to create compelling co-branded marketing media.</p>
                        <form id="myInviteConfirm1">
                            <div class="checkbox">
                                <label>
                                <input type="checkbox" value="">
                                I personally know this realtor.
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                <input type="checkbox" value="">
                                The realtor is expecting an invite from me to connect.
                                </label>
                            </div>
                            <div style="margin-bottom:15px;" class="send-method">
                                <label class="radio-inline">
                                    <input style="top:-4px" type="radio" name="send-method" data-type="email" value="email" checked>Email
                                </label>
                            </div>
                            <div class="form-group send-input send-email">
                            <label class="control-label" for="inputEmail">Recipient Email</label>
                            <input type="text" class="form-control" id="inputEmail">
                            <span id="email">Enter the email of the realtor to whom you would like us to send the invite.</span>
                            </div>
                            <div class="form-group send-input send-sms hide">
                                <label class="control-label" for="inputSMSNumber">Recipient SMS Number</label>
                                <input type="text" class="form-control" id="inputSMSNumber">
                                <span id="sms-desc">Enter the mobile phone number of the realtor to whom you would like us to send the invite SMS.</span>
                            </div>
                            <div class="form-group has-success">
                            <label class="control-label" for="confirmInitials">Initials</label>
                            <input type="text" class="form-control" id="confirmInitials1" aria-describedby="initials">
                            <span id="initials">Confirm your answers with your initials</span>
                            </div>
                            <div class="progress">
                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
                                    <span class="sr-only">0% Complete</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="msa_content">
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" data-dismiss="modal" class="btn btn-modern btn-flat msaDeny">Cancel</button>
                    <button type="button" class="btn btn-modern msaNext1 disabled">Next</button>
                    <button type="button" class="btn btn-modern msaConfirm1 hide">Accept</button>
                </div>
                </div>
            </div>
        </div>
        <!--- Modal Popup Accept Invite --->
        <div id="invite_sent_modal1" class="modal jt-modal jt-modal--invite-success fade" role="dialog">
            <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">

                <span class="pull-right closeBtn closeBtn-light" data-dismiss="modal" aria-hidden="true">&times;</span>

                <div class="modal-user-info"></div>

                <div class="modal-body">
                    <p class="modal-invite-success-title">
                    Success! You’ve sent an invite to add a new connection!
                    </p>

                    <p class="modal-invite-success-text">
                        We will notify you when they accept your invite.
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" data-dismiss="modal" class="btn btn-modern">Got it</button>
                </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
//const bbNetwork = require('../../bb-network')
//const bbNetworkSearch = require('../../bb-network-search')
import _find from "lodash/find"

export default {
    name: "MyNetworkFollowing",
    components: {
        
    },
    data() {
        return {
            isLoading: true,
            followings: [],
            alerts: [],
            loid:0,
            rid:0,
            modal_loading: false
        }
    },
    methods: {
        unfollow(loid, rid){
            this.loid = loid;
            this.rid = rid;
        },
        unfollow_now(){
            let that = this
            let rid = this.rid
            let temp = this.followings
            $.ajax({
                type: 'POST',
                url: '/api/v1/realtor-follow',
                data: JSON.stringify({
                    loid: this.loid,
                    rid: this.rid,
                    follow:0
                }),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    $("#realtor-unfollow-modal").modal("hide")
                    that.followings = temp.filter((item)=>{
                        if(item.id != rid) return item
                        }
                    )
                    BB.Toastr.success('Realtor unfollowed');
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Unfollow Failed');
                }
            });
        },
        showLogs(rid){
            let that = this
            $("#propmix-alerts-modal").modal("show")
            that.alerts = [];
            $.ajax({
                type: 'GET',
                url: '/api/v1/propmix-alerts',
                data: {
                    rid: rid,
                },
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    if(response.status == 'success'){
                        that.alerts = response.data;
                    }
					else{
						BB.Toastr.error(response.data);
					}
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Fetching alerts failed');
                }
            });
        },
        send_invite(rid, loid, status){
            let type = (status ==0) ? 'external' : 'internal'
            this.confirmMSA(loid, rid, "", status, type)
        },
        confirmMSA(loid, rid, iid, status, type = "internal") {
            var modalEl = $("#confirm_msa_modal1")
            modalEl.modal("show")
            const confirmMsaModal1 = $("#confirm_msa_modal1")

            $("#confirm_msa_modal1 .msaNext1").click(function() {
                var iid = ""
                var rid = $(this).data("rid")
                var loid = $(this).data("loid")
                var to_email = $(this).data("to_email")

                var skip = false
                // if this msa modal is being used to accept an invite, or send an internal invite, the extra confirm step can be skipped
                if ($(".bb-realtor .msaNext1, .acceptInvite .msaNext1, .internalInvite .msaNext1").length) {
                skip = true
                }

                // validate initials field
                if (!skip && !$("#confirmInitials1").val().length) {
                $("#confirm_msa_modal1 .msaNext1").addClass("disabled")
                return null
                }
                if (!skip && $(this).hasClass("disabled")) {
                return null
                }
                var to_email = $("#inputEmail").val()
                var to_sms = $("#inputSMSNumber").val()
                if (!skip && to_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(to_email)) {
                $("#inputEmail")
                    .parent()
                    .addClass("has-error")
                return null
                } else {
                $("#inputEmail")
                    .parent()
                    .removeClass("has-error")
                }
                
                $(".myInviteConfirmContent").addClass("hide")
                $(this).addClass("hide")
                $("#confirm_msa_modal1 .msaConfirm1").removeClass("hide")
                const confirmButton = modalEl.find(".msaConfirm1")
                const content = modalEl.find(".msa_content")

                confirmButton.data("iid", iid)
                confirmButton.data("rid", rid)
                confirmButton.data("loid", loid)
                confirmButton.data("status", status)
                confirmButton.attr("disabled", true)
                confirmButton.data("email", to_email)
                confirmButton.data("sms", to_sms)

                modalEl.find(".loader").show()

                content.html("")
                var type = ""
                if (iid < 1) type = "/external"

                $.get(`/listing_flyer/get_msa/${loid}/${rid}${type}`, data => {
                    modalEl.find(".loader").hide()
                    confirmButton.attr("disabled", false)
                    if (!data["msa_final"]) {
                        console.log('confirm click');
                        confirmButton.trigger("click")
                    } else {
                        content.html(data["msa_final"].replace(/(?:\r\n|\r|\n)/g, "<br />"))
                    }
                })
            })

            const inviteSentModal = $("#invite_sent_modal1")

            confirmMsaModal1.find(".msaConfirm1").click(e => {
                e.preventDefault()
                const button = $(e.target)

                let iid = button.data("iid"),
                loid = button.data("loid"),
                rid = button.data("rid"),
                status = parseInt(button.data("status"), 10),
                from = "lo",
                email = button.data("email"),
                sms = button.data("sms")

                if ($(".my-lending-network").length) {
                from = "realtor"
                }

                // const renderedUserInfo = Mustache.render(realtorInfoTpl, {
                // realtor: {
                //     image: {
                //     src: invitedRealtor.picture || imgPH,
                //     alt: invitedRealtor.name + "thumb"
                //     },
                //     name: invitedRealtor.name,
                //     agencyName: invitedRealtor.agency_name,
                //     address: invitedRealtor.address
                // }
                // })

                let inviteURL = null

                if ( status === 1) {
                inviteURL = `/listing_flyer/rlopair/send_invite/${rid}/${loid}/${from}`
                } else {
                inviteURL = `/listing_flyer/rlopair/send_external_invite?rid=${rid}&loid=${loid}&bb_sid=${window.SESSION_ID}`
                if (email.length) {
                    inviteURL = inviteURL + "&email=" + email
                }
                if (sms.length) {
                    inviteURL = inviteURL + "&sms=" + sms
                }
                }

                button.attr("disabled", true)
                confirmMsaModal1.find(".loader").show()

                $.get(inviteURL, data => {
                    const realtorRowEl = $(`[data-realtor-id="${rid}"]`)

                    confirmMsaModal1.modal("hide")

                    if (data.status && data.status === "error") {
                        Toastr.warning(data.message)
                        return
                    }
					
					realtorRowEl
                        .find(".network-table__actions")
                        .find(".btn-invite-span")
                        .html('<span class="network-table__action network-table__action--invitation-tag">Invitation sent</span>')
                    const imgPH = "/sites/all/modules/listing_flyer/images/placeholder.png"
                    const renderedUserInfo = '<div class="user">'+
                                            '<img class="user__picture" src="'+imgPH+'">'+
                                            '<div class="user__name">'+data.realtor.name+'</div>'+
                                            '<div class="user__agency">'+data.realtor.agency_name+'</div>'+
                                            '<div class="user__address"></div>'+
                                        '</div>';
										
                    // Toastr.success('Invite Sent!');
                    inviteSentModal.find(".modal-user-info").html(renderedUserInfo)
                    inviteSentModal.modal("show")
                    button.attr("disabled", false)
                    confirmMsaModal1.find(".loader").hide()
                })
            })

            
            
            var nextBtn = $("#confirm_msa_modal1 .msaNext1")
            modalEl.find("confirm_msa_modal1 .msa_content").html("")
            modalEl.modal("show")
            $(".myInviteConfirmContent").removeClass("hide")
            $("#myInviteConfirm .progress-bar").attr("aria-valuenow", "0")
            modalEl.addClass(type + "Invite")
            //if realtor, accepting invite, or sending internal invite - skip validate step
            var skip = false
            nextBtn.data("iid", iid)
            nextBtn.data("rid", rid)
            nextBtn.data("loid", loid)
            nextBtn.attr("disabled", true)
            if ($(".bb-realtor .msaNext1, .acceptInvite .msaNext1, .internalInvite .msaNext1").length) {
                skip = true
                $("#confirm_msa_modal1 .msaNext1")
                .removeClass("disabled")
                .trigger("click")
            } else {
                nextBtn.removeClass("hide")
            }

            
        },
    },
    created : function() {
        let that = this;
        this.$root.$refs.MyNetworkFollowing = this;
        jQuery.ajax({
            url: '/api/v1/propmix-follow-realtors',
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                that.followings = data;                
                that.isLoading= false;                
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        })
    },
    mounted(){
        //bbNetworkSearch.init();
        function validateInviteConfirmation(currentfield){
            var total = $("#myInviteConfirm1 input:visible").not(':radio').length
            var completed = 0
            var emailFlag = false
            // only validate visible input fields
            $("#myInviteConfirm1 input:visible").not(':radio').each(function(index, element) {
                if ($(this).val().length || this.checked) {
                    completed++
                }
            })
            return (completed / total) * 100
        }
        $("#myInviteConfirm1 input").on('input change', function(e) {
            if(this.type=="radio" && this.name=="send-method"){
                $(".send-input").addClass('hide');
                $(".myInviteConfirmContent .send-"+$(this).data('type')).removeClass('hide');
            }
            let percent_complete=validateInviteConfirmation();
            if (percent_complete==100) {
            $(".msaNext1").removeClass("disabled")
            $(".msaNext1").attr("disabled", false)
            } else {
            $(".msaNext1").addClass("disabled")
            }
            $("#myInviteConfirm1 .progress-bar").attr("aria-valuenow", percent_complete)
            $("#myInviteConfirm1 .progress-bar").css("width", percent_complete + "%")
        })
    }
}
</script>
<style lang="scss">
    p.follow-realtor-name {
		font-size: 20px;
		margin-top: 15px;
		margin-bottom: 15px;
		color: #777;
	}
	p.follow-realtor-MLSID {
		font-size: 20px;
		color: #777;
	}
	p.follow-realtor-name span, p.follow-realtor-MLSID span.popup-realtor-nmls {
		color: #222;
	}
  .following{
    background: #4ea72e !important;
    border: 1px solid #34791b  !important;
  }

  button.stop-following{
    background: #ff0000;
    color: #fff;
  }

  button.stop-following:hover{
    background: #df1313;
    color: #fff;
  }
  #propmix-alerts-modal .modal-dialog{
    width: 1200px;
  }
  #propmix-alerts-modal table thead{
    background: #eee;
  }
  .btn-invite-span {
	margin: 0px 10px;
  }
  
  .network-table__column.network-table__picture {
	width: 70px;
  }
  .network-table__column.network-table__user-info {
	width: 200px;
  }
  .network-table__column.network-table__state {
	width: 70px;
  }
  .network-table__column.network-table__agency {
	width: 200px;
  }
  .network-table__column.network-table__mls {
	width: 150px;
  }
  .network-table__column.network-table__actions {
	width: 300px;
  }
  div.network-table__column {
	flex: auto;
  }
</style>