const bbNetDeepLinking = require("./bb-network-deep-linking.js")
const Mustache = require("mustache")
const Toastr = require("toastr")
const bbRealtorInfoModal = require("./bb-network-realtor-info-modal.js")

let ttSuggestion = {},
  invitedRealtor = null,
  scrollLoad = 0,
  $contentBody,
  realtorTpl,
  agencyTpl,
  mlsTpl,
  realtorInfoTpl,
  response

const imgPH = "/sites/all/modules/listing_flyer/images/placeholder.png"

// Render Listings
const bbNetworkSearch = {
  spinner: {
    on: function() {
      $(".loader").show()
    },
    off: function() {
      $(".loader").hide()
    }
  },
  init: function() {
    initbbNetworkSearch()
  },
  cleanView: function() {
    scrollLoad = 0
    $contentBody
      .find(".network-table")
      .empty()
      .addClass("hide")
    this.spinner.on()
  },
  showNoResults: function() {
    scrollLoad = 0
    this.spinner.off()
    $contentBody.find(".network-table").removeClass("hide")
    $contentBody.find(".network-section__title").html("No results found.")
    console.log($(`#results-tabs [data-bb_type="${ttSuggestion.type}"]`))
    $(`#results-tabs [data-bb_type="${ttSuggestion.type}"]`).addClass("results-tabs__tab--active")
  },
  updateView: function(keepView) {
    if (!response) {
      return
    }

    if (!keepView) {
      this.cleanView()
    }

    function renderTPL(tpl, entries) {
      const rendered = Mustache.render(tpl, entries)
      const networkTableEl = $contentBody.find(".network-table")

      networkTableEl.append(rendered)
    }

    if (response.realtor_results && (ttSuggestion.type == "realtor" || ttSuggestion.type == "all")) {
      const realtors = {
        realtors: response.realtor_results.realtors,
        imgAlt: function() {
          return this.name + " thumb"
        },
        imgSrc: function() {
          return this.picture || imgPH
        },
        rowClass: function() {
          return (this.status == 0 || this.status == 2) ? "network-table__row--external" : "network-table__row--internal"
        },
        canInvite: function() {
          return this.paired == 1 || this.invited == 1 || this.external_invited == 1 ? false : true
        },
        isPaired: function() {
          return this.paired == 1 ? true : false
        },
        isInvited: function() {
          return this.invited == 1 || this.external_invited == 1 ? true : false
        }
      }

      renderTPL(realtorTpl, realtors)

      $('[data-action="send-invite"]').on("click", e => {
        const button = $(e.target)
        const inviteId = button.data("iid") || -1
        const loid = button.data("loid")
        const rid = button.data("rid")
        var type = "internal"
        if (button.parents(".network-table__row--external").length) {
          type = "external"
        }
        invitedRealtor = realtors.realtors.filter(realtor => parseInt(realtor.rid, 10) === rid)[0]
        confirmMSA(loid, rid, inviteId, type)
      })
    }
    if (response.agency_results && (ttSuggestion.type == "agency" || ttSuggestion.type == "all")) {
      const agencies = {
        agencies: response.agency_results.agencies,
        secureLogo: function() {
          return ( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?url="+( this.logo ? this.logo : "");
        }
      }

      renderTPL(agencyTpl, agencies)
    }
    if (response.mls_results && (ttSuggestion.type == "mls" || ttSuggestion.type == "all")) {
      const realtors = {
        realtors: response.mls_results.mls_entries,
        imgAlt: function() {
          return this.name + " thumb"
        },
        imgSrc: function() {
          return this.picture || imgPH
        },
        rowClass: function() {
          return (this.status == 0 || this.status == 2) ? "network-table__row--external" : "network-table__row--internal"
        },
        canInvite: function() {
          return this.paired == 1 || this.invited == 1 || this.external_invited == 1 ? false : true
        },
        isPaired: function() {
          return this.paired == 1 ? true : false
        },
        isInvited: function() {
          return this.invited == 1 || this.external_invited == 1 ? true : false
        }
      }
      renderTPL(mlsTpl, response.mls_results)
      $('[data-action="send-invite"]').on("click", e => {
        const button = $(e.target)
        const inviteId = button.data("iid") || -1
        const loid = button.data("loid")
        const rid = button.data("rid")
        var type = "internal"
        if (button.parents(".network-table__row--external").length) {
          type = "external"
        }
        invitedRealtor = realtors.realtors.filter(realtor => parseInt(realtor.rid, 10) === rid)[0]
        confirmMSA(loid, rid, inviteId, type)
      })
    }

    if (ttSuggestion.type) {
      $(`[data-bb_type="${ttSuggestion.type}"]`).addClass("results-tabs__tab--active")
    } else {
      $('[data-bb_type="all"]').addClass("results-tabs__tab--active")
    }

    if (!keepView) {
      const total = response.total_results,
        titleEl = $contentBody.find(".network-section__title")
      const totalItems = Number(total) === 1 ? " item" : " items"
      const title = ttSuggestion.name ? `Results for "${ttSuggestion.name}", we found ${total + totalItems}!</h3>` : ""

      titleEl.html(title)
    }

    $contentBody.find(".network-table").removeClass("hide")
    this.spinner.off()
    bbRealtorInfoModal.init()
    scrollLoad = 1
  },
  get: function() {
    const name = $.address.parameter("name") ? decodeURIComponent($.address.parameter("name")) : "",
      type = $.address.parameter("type") || "all"

    ttSuggestion = { name: name, type: type }

    $(".network-typeahead").typeahead("val", name)

    this.cleanView()
    let that = this
    let qStr = $.address.queryString() !== "" ? "?" + $.address.queryString() : ""
    let limit = 25
    qStr = qStr == "" ? "?type=all" : qStr
    qStr += "&limit=" + limit
    $.get("/realtor-network-search.json" + qStr, function(data) {
      response = data
      if (response.total_results) {
        that.updateView()
      } else {
        that.showNoResults()
      }
    })
  },
  getEntry: function(qStr, callback) {
    $.get("/realtor-network-search.json" + qStr, function(data) {
      response = data

      if (response.total_results) {
        callback(data)
      } else {
        console.log("Error: result for entry", qStr)
      }
    })
  },
  loadMore: function(query) {
    let that = this
    $.get("/realtor-network-search.json" + query, function(data) {
      response = data

      switch (ttSuggestion.type) {
        case "realtor":
          if (response.realtor_results.realtors.length) {
            that.updateView(1)
          } else {
            Toastr.warning("No more realtors!")
            that.spinner.off()
          }
          break
        case "agency":
          if (response.agency_results.agencies.length) {
            that.updateView(1)
          } else {
            Toastr.warning("No more agencies!")
            that.spinner.off()
          }
          break
        case "mls":
          if (response.mls_results.mls_entries.length) {
            that.updateView(1)
          } else {
            Toastr.warning("No more MLSs!")
            that.spinner.off()
          }
          break
        case "all":
          if (response.realtor_results.realtors.length || response.agency_results.agencies.length || response.mls_results.mls_entries.length) {
              that.updateView(1)
          } else {
              Toastr.warning("No more results!")
              that.spinner.off()
          }
          break

      }
      //that.updateView();
      // empty Toastr.warning('No more listings!');
      // more elements scrollLoad=true
    })
  }
}

const initbbNetworkSearch = function() {
  // Set vars
  $contentBody = $(".content_body")
  realtorTpl = $("#realtor-tpl").html()
  agencyTpl = $("#agency-tpl").html()
  mlsTpl = $("#mls-tpl").html()
  realtorInfoTpl = $("#realtor-info-tpl").html()

  // optional, speeds up future uses
  Mustache.parse(realtorTpl)
  Mustache.parse(agencyTpl)
  Mustache.parse(mlsTpl)
  Mustache.parse(realtorInfoTpl)
  initMsaModal()

  initTypeahead()
  bbNetDeepLinking.init(bbNetworkSearch)

  initInifiteScroll()
}

function initMsaModal() {
  const confirmMsaModal = $("#confirm_msa_modal")
  const inviteSentModal = $("#invite_sent_modal")

  confirmMsaModal.find(".msaConfirm").click(e => {
    e.preventDefault()
    const button = $(e.target)

    let iid = button.data("iid"),
      loid = button.data("loid"),
      rid = button.data("rid"),
      status = parseInt(invitedRealtor.status, 10),
      from = "lo",
      email = button.data("email"),
      sms = button.data("sms")

    if ($(".my-lending-network").length) {
      from = "realtor"
    }

    const renderedUserInfo = Mustache.render(realtorInfoTpl, {
      realtor: {
        image: {
          src: invitedRealtor.picture || imgPH,
          alt: invitedRealtor.name + "thumb"
        },
        name: invitedRealtor.name,
        agencyName: invitedRealtor.agency_name,
        address: invitedRealtor.address
      }
    })

    let inviteURL = null

    if (iid === -1 && status === 1) {
      inviteURL = `/listing_flyer/rlopair/send_invite/${rid}/${loid}/${from}`
    } else if (iid === -1) {
      inviteURL = `/listing_flyer/rlopair/send_external_invite?rid=${rid}&loid=${loid}&bb_sid=${window.SESSION_ID}`
      if (email.length) {
        inviteURL = inviteURL + "&email=" + email
      }
      if (sms.length) {
          inviteURL = inviteURL + "&sms=" + sms
      }
    }

    button.attr("disabled", true)
    confirmMsaModal.find(".loader").show()

    $.get(inviteURL, data => {
      const realtorRowEl = $(`[data-realtor-id="${invitedRealtor.rid}"]`)

      confirmMsaModal.modal("hide")
      invitedRealtor = null

      if (data.status && data.status === "error") {
        Toastr.warning(data.message)
        return
      }

      realtorRowEl
        .find(".network-table__actions")
        .html('<span class="network-table__action network-table__action--invitation-tag">Invitation sent</span>')
      // Toastr.success('Invite Sent!');
      inviteSentModal.find(".modal-user-info").html(renderedUserInfo)
      inviteSentModal.modal("show")
      button.attr("disabled", false)
      confirmMsaModal.find(".loader").hide()
    })
  })
}

$(document).ready(function() {
  var modalEl = $("#confirm_msa_modal")
  $(".msaNext").click(function() {
    var iid = $(this).data("iid")
    var rid = $(this).data("rid")
    var loid = $(this).data("loid")
    var to_email = $(this).data("to_email")

    var skip = false
    // if this msa modal is being used to accept an invite, or send an internal invite, the extra confirm step can be skipped
    if ($(".bb-realtor .msaNext, .acceptInvite .msaNext, .internalInvite .msaNext").length) {
      skip = true
    }
    // validate initials field
    if (!skip && !$("#confirmInitials").val().length) {
      $(".msaNext").addClass("disabled")
      return null
    }
    if (!skip && $(this).hasClass("disabled")) {
      return null
    }
      var to_email = $("#inputEmail").val()
      var to_sms = $("#inputSMSNumber").val()
    if (!skip && to_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(to_email)) {
      $("#inputEmail")
        .parent()
        .addClass("has-error")
      return null
    } else {
      $("#inputEmail")
        .parent()
        .removeClass("has-error")
    }
    if (!skip && to_sms && !/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(to_sms)) {
        $("#inputSMSNumber")
            .parent()
            .addClass("has-error")
        return null
    } else {
        $("#inputSMSNumber")
            .parent()
            .removeClass("has-error")
    }
    $(".myInviteConfirmContent").addClass("hide")
    $(this).addClass("hide")
    $(".msaConfirm").removeClass("hide")
    const confirmButton = modalEl.find(".msaConfirm")
    const content = modalEl.find(".msa_content")

    confirmButton.data("iid", iid)
    confirmButton.data("rid", rid)
    confirmButton.data("loid", loid)
    confirmButton.attr("disabled", true)
    confirmButton.data("email", to_email)
    confirmButton.data("sms", to_sms)

    modalEl.find(".loader").show()

    content.html("")
    var type = ""
    if (iid < 1) type = "/external"

    $.get(`/listing_flyer/get_msa/${loid}/${rid}${type}`, data => {
      modalEl.find(".loader").hide()
      confirmButton.attr("disabled", false)
      if (!data["msa_final"]) {
        confirmButton.trigger("click")
      } else {
        content.html(data["msa_final"].replace(/(?:\r\n|\r|\n)/g, "<br />"))
      }
    })
  })
  $("#confirm_msa_modal").on("hide.bs.modal", function(e) {
    $("#myInviteConfirm")[0].reset()
    $("#myInviteConfirm .progress-bar").attr("aria-valuenow", "0")
    $("#myInviteConfirm .progress-bar").css("width", "0%")
    $("#confirm_msa_modal").removeClass("acceptInvite")
    $("#confirm_msa_modal").removeClass("internalInvite")
    $("#confirm_msa_modal").removeClass("externalInvite")
    $("#confirm_msa_modal .msaConfirm").addClass("hide")
  })
  
  $(document).on('click', '.filter-by-name', function(e){
    e.preventDefault();
    let that = $(this).parent()
    that.find('.filter-wrapper').toggleClass('d-none');
    if(that.find('.filter-wrapper .filter-body').html() === ''){
      that.parent().parent().parent().find('.network-table__row').each(function(index){
        if(index !== 0){
          let title = $(this).find('.network-table__user-info-title').text();
          that.find('.filter-wrapper .filter-body').append('<label><input type="checkbox" data-realtor-id="'+$(this).attr('data-realtor-id')+'" value="'+title+'" />'+title+'</label>');
        }
      });
    }
  })

  $(document).on('click', '.filter-by-state', function(e){
    e.preventDefault();
    let that = $(this).parent()
    that.find('.filter-wrapper').toggleClass('d-none');
    let states_arr = [];
    if(that.find('.filter-wrapper .filter-body').html() === ''){
      that.parent().parent().parent().find('.network-table__row').each(function(index){
        if(index !== 0){
          let state = $(this).find('.network-table__state').text();
          if(!states_arr.includes(state) && state !== ''){            
            states_arr.push(state)
            that.find('.filter-wrapper .filter-body').append('<label><input type="checkbox" data-state="'+$(this).attr('data-state')+'" value="'+state+'" />'+state+'</label>');
          }
        }
      });
    }
  })

  $(document).on('click', '.filter-by-agency-name', function(e){
    e.preventDefault();
    let that = $(this).parent()
    that.find('.filter-wrapper').toggleClass('d-none');
    let agencies_arr = [];
    if(that.find('.filter-wrapper .filter-body').html() === ''){
      that.parent().parent().parent().find('.network-table__row').each(function(index){
        if(index !== 0){
          let agency = $(this).find('.network-table__agency').text();
          if(!agencies_arr.includes(agency) && agency !== ''){            
            agencies_arr.push(agency)
            that.find('.filter-wrapper .filter-body').append('<label><input type="checkbox" data-agency-name="'+$(this).attr('data-agency-name')+'" value="'+agency+'" />'+agency+'</label>');
          }
        }
      });
    }
  })
  $(document).on('click', '.filter-by-agency-mls', function(e){
    e.preventDefault();
    let that = $(this).parent()
    that.find('.filter-wrapper').toggleClass('d-none');
    let mls_arr = [];
    if(that.find('.filter-wrapper .filter-body').html() === ''){
      that.parent().parent().parent().find('.network-table__row').each(function(index){
        if(index !== 0){
          let mls_str='';
          $(this).find('.network-table__mls .tooltip-hover').each(function(){
            let mls_key = $(this).find('.mls-key').text();
            let mls_label = $(this).find('.btn-tooltip-label').text();
            mls_str += mls_key+',';
            if(!mls_arr.includes(mls_key) && mls_key !== ''){            
              mls_arr.push(mls_key)
              that.find('.filter-wrapper .filter-body').append('<label><input type="checkbox" data-agency-mls="'+mls_key+'" value="'+mls_key+'" />'+mls_label+'</label>');
            }
          });
          mls_str=mls_str.substring(0, mls_str.length - 1);
          $(this).attr('data-mls', mls_str)
        }
      });
    }
  })
  $(document).on('keyup', '.filter-text', function(){
    console.log('keyup');
    let typed_text = $(this).val();
    let p = $(this).parent().parent()
    p.find('.filter-body').find('input').each(function(index){
      if(!$(this).val().toLocaleLowerCase().includes(typed_text.toLocaleLowerCase())){
        $(this).parent().css('display',  'none')
      }
      else{
        $(this).parent().css('display',  'inline-block')
      }
    })
  })

  let r_arr = [];
  let s_arr = [];
  let a_arr = [];
  let m_arr = [];

  function display_rows(that){
    that.parents('.network-table').find('.network-table__row').each(function(index){
      if(index!=0){
        let mls_arr = [];
        if($(this).attr('data-mls') !== undefined){
          mls_arr = $(this).attr('data-mls').split(',');
        }
        if(r_arr.includes($(this).attr('data-realtor-id'))){
          $(this).addClass('hide');
        }
        else if(s_arr.includes($(this).attr('data-state'))){
          $(this).addClass('hide');
        }
        else if(a_arr.includes($(this).attr('data-agency-name'))){
          $(this).addClass('hide');
        }
        else if($(this).attr('data-mls') !== undefined && mls_arr.every(r=> m_arr.includes(r))){
          $(this).addClass('hide');
        }
        else{
          $(this).removeClass('hide');
        }
      }
    });
  }

  $(document).on('click', '.btn-name-filter', function(e){
    e.preventDefault();
    let p = $(this).parent().parent().find('.filter-body').find('input')
    r_arr=[];
    p.each(function(index){
      if(!$(this).is(':checked')){        
        r_arr.push($(this).attr('data-realtor-id'))
      }
      display_rows($(this))
    })
    
  })

  $(document).on('click', '.btn-state-filter', function(e){
    e.preventDefault();
    let p = $(this).parent().parent().find('.filter-body').find('input')
    s_arr = [];
    p.each(function(index){
      if(!$(this).is(':checked')){        
        s_arr.push($(this).attr('data-state'))
      }
      display_rows($(this))
    })
  })

  $(document).on('click', '.btn-agency-filter', function(e){
    e.preventDefault();
    let p = $(this).parent().parent().find('.filter-body').find('input')
    a_arr = [];
    p.each(function(index){
      if(!$(this).is(':checked')){        
        a_arr.push($(this).attr('data-agency-name'))
      }
    })
    display_rows($(this))
  })
  $(document).on('click', '.btn-mls-filter', function(e){
    e.preventDefault();
    let p = $(this).parent().parent().find('.filter-body').find('input')
    m_arr = [];
    p.each(function(index){
      if(!$(this).is(':checked')){        
        m_arr.push($(this).attr('data-agency-mls'))
      }
      
    })
    display_rows($(this))
  })
  $(document).on('click', '.btn-clear', function(e){
    let p = $(this).parent().parent()
    p.find('.filter-body').find('input').each(function(i){
      $(this).prop('checked', false)
    })
    if($(this).hasClass('clear-name')){ r_arr = [];}
    else if($(this).hasClass('clear-state')){ s_arr = [];}
    else if($(this).hasClass('clear-agency')){ a_arr = [];}
	else if($(this).hasClass('clear-mls')){ m_arr = [];}
    display_rows($(this))
    //$('.network-table__row').removeClass('hide');

  })
  
  $('body').on('click', function(e) {
    if($(e.target).closest('.filter-wrapper').length != 0) return;
    $(".filter-wrapper").addClass('d-none');
  });
  $(document).on(
    {
      mouseenter: function () {
          $(this).find('.btn-tooltip-label').css('display', 'block')
      },
      mouseleave: function () {
          $(this).find('.btn-tooltip-label').css('display', 'none')
      }
  }, '.tooltip-hover')
  
  $(document).on("click", '[data-action="follow-realtor"]', function(e) {
    var modalEl = $("#realtor-follow-modal")
    modalEl.find('.btn-follow-now').attr("data-loid", $(this).attr("data-loid"));
    modalEl.find('.btn-follow-now').attr("data-rid", $(this).attr("data-rid"));
    const parent_row = $(this).parent().parent();
    const realtor_name = parent_row.find('.network-table__user-info-title').html();
    const realtor_mlsid = parent_row.find('.network-table__mls').html();
    modalEl.find(".follow-realtor-name").html("<span class='popup-realtor-name'>Realtor: </span>" + realtor_name)
    modalEl.find(".follow-realtor-MLSID").html("<span class='popup-realtor-nmls'>Realtor MLS: </span>" + realtor_mlsid)
    modalEl.modal("show")
  });

  $(document).on("click", '[data-action="unfollow-realtor"]', function(e) {
    var modalEl = $("#realtor-unfollow-modal")
    modalEl.find('.btn-unfollow-now').attr("data-loid", $(this).attr("data-loid"));
    modalEl.find('.btn-unfollow-now').attr("data-rid", $(this).attr("data-rid"));
    const parent_row = $(this).parent().parent();
    const realtor_name = parent_row.find('.network-table__user-info-title').html();
    const realtor_mlsid = parent_row.find('.network-table__mls').html();
    modalEl.find(".follow-realtor-name").html("<span class='popup-realtor-name'>Realtor: </span>" + realtor_name)
    modalEl.find(".follow-realtor-MLSID").html("<span class='popup-realtor-nmls'>Realtor MLS: </span>" + realtor_mlsid)
    modalEl.modal("show")
  });

  $(document).on("click", ".btn-follow-now", function(e){
	  let rid = $(this).attr("data-rid")
    $.ajax({
      type: 'POST',
      url: '/api/v1/realtor-follow',
      data: JSON.stringify({
          loid: $(this).attr("data-loid"),
          rid: $(this).attr("data-rid"),
          follow:1
      }),
      contentType: "application/json",
      dataType: "json",
      success: function (response) {
          console.log(response);
		  if(response.status == "success"){
			  $("#realtor-follow-modal").modal("hide")
			  $('.btn-follow[data-rid='+rid+']').html('Following');
			  $('.btn-follow[data-rid='+rid+']').attr('data-action', 'unfollow-realtor');
			  $('.btn-follow[data-rid='+rid+']').removeClass('btn-follow').addClass('btn-unfollow');
			  BB.Toastr.success('Follow Successful');
		  }else{
			  BB.Toastr.error('Follow Failed');
		  }
      },
      error: function (xmlhttp) {
          BB.Toastr.error('Follow Failed');
      }
    });
  })

  $(document).on("click", ".btn-unfollow-now", function(e){
	  let rid = $(this).attr("data-rid")
    $.ajax({
      type: 'POST',
      url: '/api/v1/realtor-follow',
      data: JSON.stringify({
          loid: $(this).attr("data-loid"),
          rid: $(this).attr("data-rid"),
          follow:0
      }),
      contentType: "application/json",
      dataType: "json",
      success: function (response) {
          console.log(response);
		  if(response.status == "success"){
			  $("#realtor-unfollow-modal").modal("hide")
			  $('.btn-unfollow[data-rid='+rid+']').html('+ Follow');
			  $('.btn-unfollow[data-rid='+rid+']').attr('data-action', 'follow-realtor');
			  $('.btn-unfollow[data-rid='+rid+']').removeClass('btn-unfollow').addClass('btn-follow');
			  BB.Toastr.success('Realtor unfollowed');
		  }
		  else{
			  BB.Toastr.error('Unfollow Failed');
		  }
      },
      error: function (xmlhttp) {
          BB.Toastr.error('Unfollow Failed');
      }
    });
  })
})

function confirmMSA(loid, rid, iid, type = "internal") {
  var modalEl = $("#confirm_msa_modal")
  var nextBtn = $(".msaNext")
  modalEl.find(".msa_content").html("")
  modalEl.modal("show")
  $(".myInviteConfirmContent").removeClass("hide")
  $("#myInviteConfirm .progress-bar").attr("aria-valuenow", "0")
  modalEl.addClass(type + "Invite")
  //if realtor, accepting invite, or sending internal invite - skip validate step
  var skip = false
  nextBtn.data("iid", iid)
  nextBtn.data("rid", rid)
  nextBtn.data("loid", loid)
  nextBtn.attr("disabled", true)
  if ($(".bb-realtor .msaNext, .acceptInvite .msaNext, .internalInvite .msaNext").length) {
    skip = true
    $(".msaNext")
      .removeClass("disabled")
      .trigger("click")
  } else {
    nextBtn.removeClass("hide")
  }

  $("#myInviteConfirm input").on('input change', function(e) {
    if(this.type=="radio" && this.name=="send-method"){
        $(".send-input").addClass('hide');
        $(".myInviteConfirmContent .send-"+$(this).data('type')).removeClass('hide');
    }
    let percent_complete=validateInviteConfirmation();
    if (percent_complete==100) {
      $(".msaNext").removeClass("disabled")
      nextBtn.attr("disabled", false)
    } else {
      $(".msaNext").addClass("disabled")
    }
    $("#myInviteConfirm .progress-bar").attr("aria-valuenow", percent_complete)
    $("#myInviteConfirm .progress-bar").css("width", percent_complete + "%")
  })
}

function validateInviteConfirmation(currentfield){
    var total = $("#myInviteConfirm input:visible").not(':radio').length
    var completed = 0
    var emailFlag = false
    // only validate visible input fields
    $("#myInviteConfirm input:visible").not(':radio').each(function(index, element) {
        if ($(this).val().length || this.checked) {
            completed++
        }
    })
    return (completed / total) * 100
}

function initInifiteScroll() {
  // $(window).scroll(function() {
  //   //    if ($('.content_body').is(':visible') && scrollLoad && $(window).scrollTop() == $(document).height() - $(window).height() && $('.network-row').length > 0) {
  //   const elem = "#loadMore"
  //   if (
  //     scrollLoad &&
  //     $(window).scrollTop() == $(document).height() - $(window).height() &&
  //     $(elem).is(":visible") &&
  //     $(".network-section").length > 0
  //   ) {
  //     scrollLoad = 0
  //
  //     const qStr = $.address.queryString() !== "" ? "?" + $.address.queryString() : "",
  //       offset = $(".network-table").children().length,
  //       offsetStr = "&offset=" + offset,
  //       query = qStr + offsetStr
  //
  //     bbNetworkSearch.spinner.on()
  //     bbNetworkSearch.loadMore(query)
  //   }
  // })
}

// Typeahead search
function initTypeahead(query) {
  var filters = ""
  var networksBH = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: "/realtor-network-autocomplete.json",
      replace: function(url, query) {
        const currentParams = $("#network-search").data("current-params")
        var filterStr = ""
        if (currentParams)
          currentParams.forEach(function(param) {
            filterStr += "&" + param.name + "=" + param.value
          })
        return url + "?q_str=" + query + filterStr
      },
      wildcard: "%QUERY",
      cache: true,
      filter: function(data) {
        response = data
        let agencies = data.agency_results.agencies,
          mls = data.mls_results.mls_entries,
          realtors = data.realtor_results.realtors,
          results = []
        if (agencies.length) {
          for (let i = 0; i < agencies.length; i++) {
            agencies[i].type = "agency"
            results.push(agencies[i])
          }
        }
        if (mls.length) {
          for (let i = 0; i < mls.length; i++) {
            mls[i].type = "mls"
            results.push(mls[i])
          }
        }
        if (realtors.length) {
          for (let i = 0; i < realtors.length; i++) {
            realtors[i].type = "realtor"
            results.push(realtors[i])
          }
        }
        return results
      }
    }
  })

  $(".network-typeahead").typeahead(
    {
      minLength: 2,
      hint: true,
      highlight: true,
      limit: Infinity,
      classNames: {
        menu: "autocomplete autocomplete--extended",
        dataset: "autocomplete__dataset"
      },
      templates: {
        empty: `
          <div class="empty-message">
            unable to find any result that match the current query
          </div>
        `
      }
    },
    {
      name: "networksBH",
      source: networksBH,
      limit: Infinity,
      display: function(data) {
        return data.name || data.mls_name
      },
      classNames: {
        menu: "autocomplete",
        dataset: "autocomplete__dataset"
      },
      templates: {
        suggestion: function(data) {
          const type = data.type
          let image = ""
          let info = ""

          switch (data.type) {
            case "realtor":
              image = `
                <div class="autocomplete__picture">
                  <img src="${data.picture || imgPH}" alt="${data.name} thumb" />
                </div>
              `
              info = `
                <p class="autocomplete__info-title">${data.name}</p>
                <p class="autocomplete__info-subtitle">${data.agency_name}</p>
              `
              break
            case "agency":
              image = data.logo
                ? `
                <div class="autocomplete__picture autocomplete__picture--agency">
                  <img src="${( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?url="+data.logo}" alt="${data.name} thumb" />
                </div>
              `
                : ""
              info = `
                <p class="autocomplete__info-title">${data.name}</p>
                <p class="autocomplete__info-subtitle">${data.address}</p>
              `
              break
            default:
              info = `
                <p class="autocomplete__info-title">${data.mls_name}</p>
              `
          }

          const str = `
            <div class="autocomplete__row">
              ${image}
              <div class="autocomplete__info">
                ${info}
              </div>
            </div>
          `

          return str
        }
      }
    }
  )

  $(".network-typeahead")
    .bind("typeahead:asyncrequest", function() {
      $(".tt-loading").removeClass("hide")
    })
    .bind("typeahead:asyncreceive", function() {
      $(".tt-loading").addClass("hide")
    })
    .bind("typeahead:select", function(ev, suggestion) {
      let key = suggestion.name ? "name" : "mls_name"
      ttSuggestion = { name: suggestion[key], type: suggestion["type"] }
      $.address.parameter(key, suggestion[key])
      $.address.parameter("type", suggestion["type"])
      $.address.update()
      bbNetworkSearch.updateView()
    })

  $("#tt-search-btn").click(function() {
    const val = $(this)
      .parent(".network-search")
      .find(".tt-input")
      .typeahead("val")

      const search_mls = $(this)
      .parent(".network-search")
      .find(".search-mls")
      .is(':checked')
      console.log('search-mls:  ', search_mls);
    doSearch(val, search_mls)
  })
  $('.search-mls').on('click', function(){
    if($(this).is(":checked")){
      $('#tt-search-input').attr('placeholder', 'Search by Realtor Last Name')
    }
    else{
      $('#tt-search-input').attr('placeholder', 'Search by Realtor MLS ID#')
    }
  })
  function AddEventSearch() {
    const ttSearchInput = document.getElementById("tt-search-input")
    const OnSearch = function(e) {
      e.target.blur()
      doSearch(e.target.value)
    }
    ttSearchInput.addEventListener("search", OnSearch, false)
  }

  setTimeout(AddEventSearch, 1000)

  const doSearch = function(val, search_mls=false) {
    ttSuggestion = { name: "name", type: "all" }


    $.address.parameter("name", val)
    $.address.parameter("type", "all")
    if(search_mls){
      $.address.parameter("search_mls", 1)
    }
    else{
      $.address.parameter("search_mls", 0)
    }
    $.address.update()
    //bbNetworkSearch.updateView()
  }
}

module.exports = bbNetworkSearch
