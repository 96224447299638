<doc>
    Upload Template Preview Modal Vue.js component
  </doc>

<template>
    <transition-group
        name="modal"
        v-on:before-enter="beforeEnter"
        v-on:after-enter="afterEnter"
        v-on:after-leave="afterLeave"
    >
        <div 
            key="modal" 
            class="flyer-modal"
            tabindex="-1"
        >
            <div class="modal-dialog flyer-modal-dialog">
                <div class="modal-content flyer-modal-content">
                    <div class="modal-header flyer-modal-header">
                        <h4 class="modal-title flyer-modal-title">
                            {{title}}
                        </h4>
                        <button
                            class="close"
                            theme=""
                            type="button"
                            aria-label="Close"
                            v-on:click="cancel"
                            >
                            <span class="closeBtn" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body flyer-modal-body modal-para">
						<p class="p-info" style="font-size: 20px; margin-bottom: 5px;">Please upload the flyer preview image. </p><p class="p-info" style="margin-bottom: 20px;"><span>This uploaded image will be shown as a flyer preview.</span></p>
						<div style="background-color: #eee; padding: 15px;">
							<input type="file" v-on:change="uploadFile" />
						</div>
                    </div>
                    <div class="modal-footer flyer-modal-footer">
                        <button class="btn btn-modern" @click="update">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div key="backdrop" class="modal-backdrop in" v-if="isVisible"></div>
    </transition-group>
</template>

<script>
  import { createNamespacedHelpers } from "vuex"

  
  const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "uploadPreviewModal"
)

export default {
    name: "FlyerTemplateUploadPreview",
    components: {  },
    data() {
        return {
            isVisible: true,
            fileUrl: null
        }
    },
    props: {
        title: { type: String },
        templateId:{type: String}
    },
    mounted(){
        console.log('template_id',this.templateId);
        this.isVisible = true;
    },
    async created() {
    },
    methods: Object.assign(
    {
        cancel() {
            this.$emit("hideUploadPreviewModal")
            this.isVisible = false;
        },
    },
    {
        uploadFile(e) {
            this.file = e.target.files[0]

            const data = new FormData()               
            data.append("files[img]", e.target.files[0])
            data.append("bb_sid", window.SESSION_ID)

            let url = "/bb-file-upload.json"
            fetch(url, {
            method: "POST",
            body: data,
            credentials: "same-origin"
            })
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)   
                this.fileUrl = data.message.url
            })
            .catch(err => {
                console.error(err)
                vm.isLoading = false
                vm.hasError = "There was an error, please try again."
            })
        },
        async update(){
            console.log('template_id',this.templateId);
                let that = this
            $.ajax({
                type: 'POST',
                url: '/api/v1/template-manager-upload-preview',
                data: JSON.stringify({
                    id: this.templateId,
                    preview_url: this.fileUrl
                }),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    // Display errors.

                    if (response.status === 'success') {
                        that.$emit("updateTemplatePreview", {template_id:that.templateId, preview_url: that.fileUrl} )
                        that.cancel()
                        BB.Toastr.success('Upload Successful');
                    }
                    else{
                        BB.Toastr.error('Upload Failed');
                    }
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Upload Failed');
                }
            });
            
        },
        beforeEnter() {
            this.$emit("beforeEnter")
            document.body.classList.add("modal-open")
        },
        afterEnter() {
            this.$emit("afterEnter")
        },
        afterLeave() {
            document.body.classList.remove("modal-open")
        },
    }
    ),
    watch: {
        
    }
}
</script>