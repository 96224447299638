var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      attrs: { name: "modal" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _c(
        "div",
        { key: "modal", staticClass: "flyer-modal", attrs: { tabindex: "-1" } },
        [
          _c("div", { staticClass: "modal-dialog flyer-modal-dialog" }, [
            _c("div", { staticClass: "modal-content flyer-modal-content" }, [
              _c("div", { staticClass: "modal-header flyer-modal-header" }, [
                _c("h4", { staticClass: "modal-title flyer-modal-title" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.title) +
                      "\n                        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { theme: "", type: "button", "aria-label": "Close" },
                    on: { click: _vm.cancel }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "closeBtn",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_vm._v("×")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body flyer-modal-body modal-para" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "p-info",
                      staticStyle: {
                        "font-size": "20px",
                        "margin-bottom": "5px"
                      }
                    },
                    [_vm._v("Please upload the flyer preview image. ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "p-info",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "This uploaded image will be shown as a flyer preview."
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#eee",
                        padding: "15px"
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "file" },
                        on: { change: _vm.uploadFile }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer flyer-modal-footer" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-modern", on: { click: _vm.update } },
                  [
                    _vm._v(
                      "\n                            Update\n                        "
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { key: "backdrop", staticClass: "modal-backdrop in" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b1f6fe0", { render: render, staticRenderFns: staticRenderFns })
  }
}