var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Connections" } },
            [_c("MyNetworkConnections")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Manual" } },
            [_c("MyNetworkManualListing")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Following" } },
            [_c("MyNetworkFollowing")],
            1
          ),
          _vm._v(" "),
          _c("Tab", { attrs: { name: "Archive" } }, [_c("MyNetworkArchive")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-05dd92ff", { render: render, staticRenderFns: staticRenderFns })
  }
}