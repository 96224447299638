var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-circle pull-right",
        class: { cancel: _vm.editorActive },
        attrs: { type: "button" },
        on: { click: _vm.addNew }
      },
      [_c("div", [_vm._v("+")])]
    ),
    _vm._v(" "),
    !_vm.editorActive && _vm.available_templates
      ? _c(
          "div",
          [
            _vm.tableLoading
              ? _c("div", [_vm._v("\n        Loading ...\n      ")])
              : _c("div", { staticClass: "row row-edit-order" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    !_vm.isOrderingEnabled
                      ? _c(
                          "button",
                          {
                            staticClass: "edit-order btn btn-modern purple",
                            attrs: { type: "button" },
                            on: { click: _vm.enableOrdering }
                          },
                          [_vm._v("Edit Order")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isOrderingEnabled
                      ? _c(
                          "button",
                          {
                            staticClass: "save-order btn btn-modern",
                            attrs: { type: "button" },
                            on: { click: _vm.saveOrdering }
                          },
                          [_vm._v("Save Order")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("vue-good-table", {
                        attrs: {
                          columns: _vm.headers,
                          rows: _vm.templateData,
                          "search-options": {
                            enabled: true,
                            searchFn: _vm.tableSearch,
                            trigger: "enter"
                          },
                          "pagination-options": {
                            perPage: 50,
                            enabled: true
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "active"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                props.column.field == "preview"
                                  ? _c("span", [
                                      _c("img", {
                                        attrs: {
                                          width: "150px",
                                          src: props.row.preview
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                props.column.field == "alias"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(props.row.alias) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                props.column.field == "tags"
                                  ? _c(
                                      "span",
                                      _vm._l(props.row.tags, function(item) {
                                        return _c(
                                          "ul",
                                          { key: "tag-" + item.id },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.name) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      })
                                    )
                                  : props.column.field == "brandPermissions"
                                    ? _c(
                                        "span",
                                        [
                                          _vm._l(props.row.orgNames, function(
                                            item
                                          ) {
                                            return _c("ul", { key: item }, [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item) +
                                                  "\n                "
                                              )
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _vm._l(props.row.brandNames, function(
                                            item
                                          ) {
                                            return _c(
                                              "ul",
                                              {
                                                key: item,
                                                staticStyle: { display: "none" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : props.column.field == "excludedBrands"
                                      ? _c(
                                          "span",
                                          _vm._l(
                                            props.row.excludedBrandNames,
                                            function(item) {
                                              return _c("ul", { key: item }, [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item) +
                                                    "\n                "
                                                )
                                              ])
                                            }
                                          )
                                        )
                                      : props.column.field == "edit"
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.configSelectedTemplate(
                                                      props.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("View/Edit")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "delete-button",
                                                on: {
                                                  click: function($event) {
                                                    _vm.deleteSelectedTemplate(
                                                      props.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Delete")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.uploadPreview(
                                                      props.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Upload Image")]
                                            )
                                          ])
                                        : props.column.field == "update"
                                          ? _c("span", [
                                              _c(
                                                "button",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.updateBrandPermissions(
                                                        props.row.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Update Brand Permissions"
                                                  )
                                                ]
                                              )
                                            ])
                                          : props.column.field == "name"
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      props.formattedRow[
                                                        props.column.field
                                                      ]
                                                    ) +
                                                    "\n              "
                                                )
                                              ])
                                            : _vm._e(),
                                _vm._v(" "),
                                props.column.field == "order"
                                  ? _c("span", [
                                      !_vm.isOrderingEnabled
                                        ? _c("span", [
                                            _vm._v(_vm._s(props.row.order))
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isOrderingEnabled
                                        ? _c("input", {
                                            staticClass: "ordertxt",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: props.row.order
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.sortChange(
                                                  props.row.id,
                                                  $event.data
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  : _c("span")
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("FlyerTemplateUploadPreview", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isUploadPreviewModalVisible,
                            expression: "isUploadPreviewModalVisible"
                          }
                        ],
                        ref: "modal",
                        attrs: {
                          title: "Upload Preview Image",
                          templateId: _vm.template_id
                        },
                        on: {
                          hideUploadPreviewModal: _vm.hideUploadPreviewModal,
                          updateTemplatePreview: _vm.updateTemplatePreview
                        }
                      })
                    ],
                    1
                  )
                ]),
            _vm._v(" "),
            _c("FlyerTemplateModal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isModalVisible,
                  expression: "isModalVisible"
                }
              ],
              ref: "modal",
              attrs: {
                title: _vm.flyerTitle,
                orgAndBrandNames: this.orgAndBrandNames
              },
              on: { cancel: _vm.cancel }
            })
          ],
          1
        )
      : _vm.editorActive
        ? _c(
            "div",
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "30px"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "loader loader-circle tt-loading"
                      })
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "flyer-tm-top" },
                    [
                      _c("div", { staticClass: "row row-template-tag-block" }, [
                        _c("div", { staticClass: "col-xs-3" }, [
                          _c("div", { staticClass: "templateName" }, [
                            _c("div", { staticClass: "template-heading" }, [
                              _vm._v("Template Name")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.name,
                                  expression: "name"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.name = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-2" }, [
                          _c("div", { staticClass: "templateName" }, [
                            _c("div", { staticClass: "template-heading" }, [
                              _vm._v("Template Alias")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.alias,
                                  expression: "alias"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.alias },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.alias = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-3" }, [
                          _c("div", { staticClass: "template-tags" }, [
                            _c("div", { staticClass: "template-heading" }, [
                              _vm._v("Tags")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "template-tags-wrapper" },
                              [
                                _c("Multiselect", {
                                  attrs: {
                                    "max-height": 500,
                                    options: _vm.activeTags,
                                    multiple: true,
                                    "close-on-select": false,
                                    "clear-on-select": false,
                                    "preserve-search": true,
                                    placeholder: "Pick some tags",
                                    selectLabel: "Click to select",
                                    deselectLabel: "Click to remove",
                                    label: "name",
                                    "track-by": "id",
                                    "preselect-first": false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var values = ref.values
                                        var search = ref.search
                                        var isOpen = ref.isOpen
                                        return [
                                          values.length && !isOpen
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " options selected"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.templateTags,
                                    callback: function($$v) {
                                      _vm.templateTags = $$v
                                    },
                                    expression: "templateTags"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  { on: { click: _vm.showTagsModal } },
                                  [_c("i", { staticClass: "fas fa-edit" })]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-2",
                            staticStyle: { "text-align": "right" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "template-heading text-left" },
                              [_vm._v("Document Size")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.document_size,
                                    expression: "document_size"
                                  }
                                ],
                                staticClass: "select-size mt-0",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.document_size = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", value: "" } },
                                  [_vm._v("Please select one")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { key: "letter", attrs: { value: "letter" } },
                                  [_vm._v("Letter")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    key: "postcard",
                                    attrs: { value: "postcard" }
                                  },
                                  [_vm._v("Postcard(4x6)")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { key: "sign", attrs: { value: "sign" } },
                                  [_vm._v("Sign Banner(4x10)")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-2",
                            staticStyle: { "text-align": "right" }
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.type,
                                    expression: "type"
                                  }
                                ],
                                staticClass: "select-type",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.type = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", value: "" } },
                                  [_vm._v("Please select one")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { key: "flyer", attrs: { value: "flyer" } },
                                  [_vm._v("flyer")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { key: "share", attrs: { value: "share" } },
                                  [_vm._v("share")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    key: "quote_flyer",
                                    attrs: { value: "quote_flyer" }
                                  },
                                  [_vm._v("quote_flyer")]
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "Split",
                        { attrs: { gutterSize: 5 } },
                        [
                          _c(
                            "SplitArea",
                            {
                              staticClass: "leftPane",
                              attrs: { size: _vm.leftWidth, minSize: 100 }
                            },
                            [
                              _c("div", [
                                _c("iframe", {
                                  class: _vm.type,
                                  attrs: { srcdoc: _vm.preview }
                                })
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "SplitArea",
                            {
                              staticClass: "rightPane",
                              attrs: { size: _vm.rightWidth }
                            },
                            [
                              _c("div", { staticClass: "tabWrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "drawer-tab",
                                    class: { active: _vm.drawerToggleActive },
                                    on: { click: _vm.toggleDrawer }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-chevron-right"
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "paneHeader",
                                  class: { changed: _vm.changed }
                                },
                                [
                                  _vm._v(
                                    "\n              Source Code\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _c(
                                  "form",
                                  {
                                    ref: "templateForm",
                                    staticClass: "form",
                                    attrs: { id: "template-form" },
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "fieldset",
                                      [
                                        _c("codemirror", {
                                          ref: "myCm",
                                          attrs: {
                                            value: _vm.template,
                                            options: _vm.cmOptions
                                          },
                                          on: { input: _vm.onCmCodeChange }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "activeCheckbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.active,
                              expression: "active"
                            }
                          ],
                          attrs: { type: "checkbox", id: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.active)
                              ? _vm._i(_vm.active, null) > -1
                              : _vm.active
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.active,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.active = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.active = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.active = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "checkbox" } }, [
                          _vm._v("Active")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "submitBtn btn btn-modern",
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "submitBtn btn btn-modern purple",
                          on: { click: _vm.convert }
                        },
                        [
                          !_vm.convert_in_progress
                            ? _c("span", [_vm._v("Convert")])
                            : _c("span", [
                                _c("div", {
                                  staticClass: "loader loader-circle tt-loading"
                                })
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.result
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "panel panel-default",
                                staticStyle: { "margin-top": "60px" }
                              },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c("div", { staticClass: "panel-body" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.result.file
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.name) +
                                          " " +
                                          _vm._s(_vm.dateStr)
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("FlyerTemplateTagsModal", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTagsModalVisible,
                    expression: "isTagsModalVisible"
                  }
                ],
                ref: "modal",
                attrs: { title: "Tags" },
                on: { hideTagsModal: _vm.hideTagsModal, setTags: _vm.setTags }
              })
            ],
            1
          )
        : _c("div", [_vm._v("\n      Loading ...\n    ")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("PDF Ready")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7492a6c6", { render: render, staticRenderFns: staticRenderFns })
  }
}